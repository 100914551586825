import React from 'react';

type Props = {
  id: string;
};

export default function EditProtocolInstanceScreen({ id }: Props) {
  return (
    <>
      <h1>Edit protocol use</h1>
      <p>Use ID: {id}</p>
    </>
  );
}
