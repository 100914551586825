import React, { useCallback, useEffect, useMemo } from 'react';

import { useQuery } from '@apollo/client';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

import { QUERY_BREADCRUMBS } from 'client/app/api/gql/queries';
import CurrentEntityProvider from 'client/app/components/nav/breadcrumbs/BreadcrumbsEntityContext';
import NavNode, {
  CollapsedNode,
  NodeSkeleton,
  RootNode,
} from 'client/app/components/nav/breadcrumbs/components/NavNode';
import {
  type NavNode as NavNodeType,
  BreadcrumbsPayload,
} from 'client/app/components/nav/breadcrumbs/types';
import useBreadcrumbsEntity from 'client/app/components/nav/breadcrumbs/useBreadcrumbsEntity';
import useCurrentScreenEntity from 'client/app/components/nav/breadcrumbs/useCurrentScreenEntity';
import DocsButton from 'client/app/components/nav/DocsButton';
import IntercomButton from 'client/app/components/nav/IntercomButton';
import StandaloneToolsButton from 'client/app/components/nav/StandaloneToolsButton';
import UserDetailsMenu from 'client/app/components/nav/UserDetailsMenu';
import { useUserProfile } from 'client/app/hooks/useUserProfile';
import SynthaceBrandmarkLight from 'common/assets/SynthaceBrandmarkLight';
import { useFeatureToggle } from 'common/features/useFeatureToggle';
import Colors from 'common/ui/Colors';
import { TOP_NAV_HEIGHT } from 'common/ui/components/TopNav/topNavStyles';
import { getEnvColour, getEnvName } from 'common/ui/lib/envs';

const NO_NODES = [] as BreadcrumbsPayload;

function BreadcrumbsHeader() {
  const isShowColorLogo = useFeatureToggle('SHOW_COLOR_LOGO');
  const showStandaloneTools = useFeatureToggle('STANDALONE_TOOLS');

  const envName = getEnvName(window.location);
  const envColour = isShowColorLogo ? 'multicolor' : getEnvColour(envName);

  const userProfile = useUserProfile();

  const currentScreenEntity = useCurrentScreenEntity();
  const currentEntity = useBreadcrumbsEntity({
    entity: currentScreenEntity,
  } as NavNodeType);

  const { data, loading, refetch } = useQuery(QUERY_BREADCRUMBS, {
    variables: {
      id: currentEntity.id,
      entity: currentScreenEntity,
    },
    skip: !currentEntity.id,
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
  });
  const navNodes = data?.breadcrumbs ?? NO_NODES;

  const handleMessageEventFromVisserver = useCallback(
    async (ev: MessageEvent) => {
      if (
        ev.source === null ||
        ev.source instanceof MessagePort ||
        ev.source instanceof ServiceWorker ||
        ev.origin !== window.location.origin
      ) {
        console.warn('unexpected origin for message', ev);
        return;
      }
      if (ev.data.name === 'methodLoaded' && ev.data.payload.method_id) {
        /**
         * `methodLoaded` informs us that visserver is rendering a new method (e.g. a version).
         * and we should refetch the correct name and update the URL.
         */
        const methodId = ev.data.payload.method_id as MethodId;

        /**
         * We arent't able to use react-router to update the search params here. This is because
         * the visserver components consume the search params from react-router as props, so any
         * change in those causes those components to re-render (and re-load visserver content).
         * We don't want that to happen, because visserver handles the loading of the content itself.
         * So, we have to use the native window replaceState to update this instead.
         * The updating of the URL here is primarily to allow users to copy the URL with the correct
         * method_id.
         */
        const url = window.location.href;
        const hasParams = url.includes('?');
        const existingParams = hasParams ? url.split('?')[1] : '';
        const urlWithoutParams = hasParams ? url.split('?')[0] : url;
        const newParams = new URLSearchParams(existingParams);
        newParams.set('method_id', methodId);
        window.history.replaceState(
          undefined,
          '',
          urlWithoutParams + '?' + newParams.toString(),
        );

        await refetch({
          id: methodId,
          entity: currentScreenEntity,
        });
      }
    },
    [currentScreenEntity, refetch],
  );

  useEffect(() => {
    window.addEventListener('message', handleMessageEventFromVisserver);
    return () => window.removeEventListener('message', handleMessageEventFromVisserver);
  });

  const isNarrowWidth = useMediaQuery(`(max-width:900px)`);
  const showCollapsedHeader = loading
    ? isNarrowWidth
    : isNarrowWidth && navNodes.length > 3;

  const placeholders = useMemo(() => {
    return showCollapsedHeader
      ? Array.from({ length: 3 })
      : Array.from({ length: currentEntity.level });
  }, [currentEntity.level, showCollapsedHeader]);

  const nodesToRender = useMemo(() => {
    if (loading) {
      return placeholders.map((_, index) => (
        <NodeSkeleton key={index} expanded={index === placeholders.length - 1} />
      ));
    }
    if (!showCollapsedHeader) {
      return navNodes.map((node, index) => (
        <NavNode key={index} node={node} expanded={index === navNodes.length - 1} />
      ));
    }

    const nodesToCollapse = navNodes.slice(1, navNodes.length - 1);
    const firstNode = navNodes[0];
    const lastNode = navNodes[navNodes.length - 1];

    return [
      <NavNode key={0} node={firstNode} />,
      <CollapsedNode key={1} nodes={nodesToCollapse} />,
      <NavNode key={navNodes.length - 1} node={lastNode} expanded />,
    ];
  }, [loading, navNodes, placeholders, showCollapsedHeader]);

  return (
    <Container>
      <Nav data-heap-tracking="breadcrumbs-header-nav">
        <RootNode>
          <StyledSynthaceBrandmark logoColour={envColour} />
        </RootNode>
        <CurrentEntityProvider currentEntity={currentScreenEntity}>
          {nodesToRender}
        </CurrentEntityProvider>
      </Nav>
      {userProfile && (
        <RightSection>
          {showStandaloneTools && <StandaloneToolsButton />}
          <IntercomButton userProfile={userProfile} />
          <DocsButton />
          <UserDetailsMenu userProfile={userProfile} useAvatar />
        </RightSection>
      )}
    </Container>
  );
}

export default React.memo(BreadcrumbsHeader);

const Container = styled(Paper)`
  display: grid;
  grid-template-columns: 1fr min-content;
  min-height: ${TOP_NAV_HEIGHT};
  max-height: ${TOP_NAV_HEIGHT};

  background-color: ${Colors.GREY_0};
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.08);
  z-index: 2;
  border-bottom: 1px solid ${Colors.GREY_30};
`;

const Nav = styled('nav')`
  display: flex;
  align-items: stretch;
  overflow: hidden;
`;

const RightSection = styled('section')(({ theme }) => ({
  display: 'grid',
  gridAutoFlow: 'column',
  alignItems: 'center',
  color: Colors.TEXT_PRIMARY,
  columnGap: theme.spacing(5),
}));

const StyledSynthaceBrandmark = styled(SynthaceBrandmarkLight)`
  width: 32px;
  height: 32px;
  cursor: pointer;
`;
