import React, { useState } from 'react';

import MuiDivider from '@mui/material/Divider';
import InputAdornment from '@mui/material/InputAdornment';
import MenuItem from '@mui/material/MenuItem';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';

import { calculateMass } from 'client/app/apps/standalone-tools/mass-molarity-calcuator/calculations';
import { getUnit } from 'common/lib/units';
import Button from 'common/ui/components/Button';
import TextField from 'common/ui/filaments/TextField';

const VOLUME_UNIT_OPTIONS = [getUnit('ul'), getUnit('ml'), getUnit('l')];

const CONCENTRATION_UNIT_OPTIONS = [
  getUnit('nMol/l'),
  getUnit('uMol/l'),
  getUnit('mMol/l'),
  getUnit('Mol/l'),
];

export default function MassMolarityCalculator() {
  const [mass, setMass] = useState<number | null>(null);
  const [formulaWeight, setFormulaWeight] = useState<number | null>(null);
  const [volume, setVolume] = useState<number | null>(null);
  const [volumeUnitCoefficient, setVolumeUnitCoefficient] = useState<number>(1);
  const [concentration, setConcentration] = useState<number | null>(null);
  const [concentrationUnitCoefficient, setConcentrationUnitCoefficient] =
    useState<number>(1);

  const [isFormulaWeightErrored, setIsFormulaWeightErrored] = useState(false);
  const [isVolumeErrored, setIsVolumeErrored] = useState(false);
  const [isConcentrationErrored, setIsConcentrationErrored] = useState(false);

  function calculate() {
    const isFormulaWeightValid = isFieldValid(formulaWeight);
    const isVolumeValid = isFieldValid(volume);
    const isConcentrationValid = isFieldValid(concentration);

    setIsFormulaWeightErrored(!isFormulaWeightValid);
    setIsVolumeErrored(!isVolumeValid);
    setIsConcentrationErrored(!isConcentrationValid);

    if (isFormulaWeightValid && isVolumeValid && isConcentrationValid) {
      const result = calculateMass(
        volume,
        volumeUnitCoefficient,
        concentration,
        concentrationUnitCoefficient,
        formulaWeight,
      );

      setMass(result);
    } else {
      setMass(null);
      return;
    }
  }

  return (
    <>
      <FieldsContainer>
        <Label>Formula weight</Label>
        <StyledTextField
          label="value"
          inputMode="decimal"
          InputProps={{
            endAdornment: <InputAdornment position="end">g&#47;mol</InputAdornment>,
          }}
          value={formulaWeight}
          onChange={e => {
            setFormulaWeight(getEventChangeValue(e));
          }}
          error={isFormulaWeightErrored}
          helperText={
            isFormulaWeightErrored
              ? 'The formula weight must be a positive, numeric value'
              : undefined
          }
        />
        <Label>Desired final volume</Label>
        <StyledTextField
          label="value"
          inputMode="decimal"
          value={volume}
          onChange={e => {
            setVolume(getEventChangeValue(e));
          }}
          error={isVolumeErrored}
          helperText={
            isVolumeErrored ? 'The volume must be a positive, numeric value' : undefined
          }
        />
        <StyledTextField
          label="unit"
          select
          value={volumeUnitCoefficient}
          onChange={e => {
            const numericValue = Number(e.target.value);
            if (Number.isNaN(numericValue)) {
              return;
            }
            setVolumeUnitCoefficient(numericValue);
          }}
        >
          {VOLUME_UNIT_OPTIONS.map(option => (
            <MenuItem key={option.label} value={option.coefficient}>
              {option.label}
            </MenuItem>
          ))}
        </StyledTextField>
        <Label>Desired concentration</Label>
        <StyledTextField
          label="value"
          inputMode="decimal"
          value={concentration}
          onChange={e => {
            setConcentration(getEventChangeValue(e));
          }}
          error={isConcentrationErrored}
          helperText={
            isConcentrationErrored
              ? 'The concentration must be a positive, numeric value'
              : undefined
          }
        />
        <StyledTextField
          label="unit"
          select
          value={concentrationUnitCoefficient}
          onChange={e => {
            const numericValue = Number(e.target.value);
            if (Number.isNaN(numericValue)) {
              return;
            }
            setConcentrationUnitCoefficient(numericValue);
          }}
        >
          {CONCENTRATION_UNIT_OPTIONS.map(option => (
            <MenuItem key={option.label} value={option.coefficient}>
              {option.label}
            </MenuItem>
          ))}
        </StyledTextField>
      </FieldsContainer>
      <Divider />
      <ResultSection>
        <Button variant="secondary" onClick={calculate}>
          Calculate
        </Button>
        <StyledTextField
          value={mass}
          InputProps={{
            endAdornment: <InputAdornment position="end">g</InputAdornment>,
            startAdornment: <InputAdornment position="start">Mass</InputAdornment>,
          }}
          contentEditable={false}
        />
      </ResultSection>
    </>
  );
}

function isFieldValid(val: number | null): val is number {
  if (val === null) {
    return false;
  }

  if (Number.isNaN(val)) {
    return false;
  }

  return val >= 0;
}

function getEventChangeValue(
  e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
) {
  const numericValue = Number(e.target.value);
  if (Number.isNaN(numericValue)) {
    return null;
  } else if (numericValue === 0) {
    return null;
  }
  return numericValue;
}

const FieldsContainer = styled('div')(({ theme }) => ({
  display: 'grid',
  gridTemplateColumns: '4fr 1fr',
  gap: theme.spacing(3),
}));

const Label = styled(Typography)({
  gridColumn: 'span 2',
});

const Divider = styled(MuiDivider)(({ theme }) => ({
  marginTop: theme.spacing(4),
}));

const ResultSection = styled('div')(({ theme }) => ({
  display: 'grid',
  gridTemplateColumns: 'auto 1fr',
  gap: theme.spacing(4),

  marginTop: theme.spacing(4),
}));

const StyledTextField = styled(TextField)({
  '& input': {
    textAlign: 'right',
  },
});
