export function calculateMass(
  volume: number,
  volumeUnitCoefficient: number,
  concentration: number,
  concentrationUnitCoefficient: number,
  formulaWeight: number,
) {
  const volumeInLitres = volume * volumeUnitCoefficient;
  const concentrationInMol = concentration * concentrationUnitCoefficient;

  return concentrationInMol * volumeInLitres * formulaWeight;
}
