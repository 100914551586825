import React from 'react';

import Close from '@mui/icons-material/Close';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';

import MassMolarityCalculator from 'client/app/apps/standalone-tools/mass-molarity-calcuator/MassMolarityCalculator';
import Colors from 'common/ui/Colors';

export enum StandaloneTool {
  MASS_MOLARITY,
  COMING_SOON,
}

function useToolTitle(tool: StandaloneTool) {
  switch (tool) {
    case StandaloneTool.MASS_MOLARITY:
      return 'Molarity Calculator';
    case StandaloneTool.COMING_SOON:
      return 'Tool';
  }
}

function useToolComponent(tool: StandaloneTool) {
  switch (tool) {
    case StandaloneTool.MASS_MOLARITY:
      return <MassMolarityCalculator />;
    case StandaloneTool.COMING_SOON:
      return <Typography>Coming soon!</Typography>;
  }
}

type StandaloneToolDialogueProps = {
  open: boolean;
  onClose: () => void;
  tool: StandaloneTool;
};

export default function StandaloneToolDialogue({
  onClose,
  open,
  tool,
}: StandaloneToolDialogueProps) {
  const handleClose = () => {
    onClose();
  };

  const toolTitle = useToolTitle(tool);

  const toolComponent = useToolComponent(tool);

  return (
    <Dialog onClose={handleClose} open={open} fullWidth>
      <DialogueHeader>
        <ToolTitle variant="h1">{toolTitle}</ToolTitle>
        <IconButton onClick={handleClose}>
          <Close />
        </IconButton>
      </DialogueHeader>
      <DialogContent>{toolComponent}</DialogContent>
    </Dialog>
  );
}

const DialogueHeader = styled('div')(({ theme }) => ({
  padding: theme.spacing(5),
  display: 'grid',
  gridAutoFlow: 'column',
  gridTemplateColumns: '1fr auto',
}));

const ToolTitle = styled(DialogTitle)(({ theme }) => ({
  padding: theme.spacing(3, 0, 0, 3),
  fontSize: 32,
  fontWeight: 900,
  fontFamily: 'Inter',
  lineHeight: '32px',
  '&::after': {
    content: '"."',
    color: Colors.SECONDARY_MAIN,
  },
}));
