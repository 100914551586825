import React from 'react';

import { Link } from 'react-router-dom';

import { ExperimentsNavigation } from 'client/app/apps/experiments/ExperimentsNavigation';
import { protocolsRoutes } from 'client/app/lib/nav/actions';

export default function ProtocolsScreen() {
  return (
    <ExperimentsNavigation title="Protocols">
      <h1>List Protocols</h1>
      <Link to={protocolsRoutes.createProtocolInstance.getPath({ id: '123456789' })}>
        Create new Protocol Use
      </Link>
      <Link to={protocolsRoutes.editProtocol.getPath({ id: 'abcdefgh', version: '1' })}>
        Edit existing Protocol
      </Link>
    </ExperimentsNavigation>
  );
}
